// src/contexts/LanguageContext.js
import React, { createContext, useContext, useState, useEffect } from "react"

const LanguageContext = createContext()

export const useLanguage = () => useContext(LanguageContext)

export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("HR") // Default language

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage")
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage)
    }
  }, [])

  const selectLanguage = language => {
    setSelectedLanguage(language)
    localStorage.setItem("selectedLanguage", language)
  }

  return (
    <LanguageContext.Provider value={{ selectedLanguage, selectLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}
