// import "firebase/auth"
// import "firebase/firestore"
// import "firebase/functions"
// import "firebase/database"
import "./src/css/main.css"
import React from "react"
import { LanguageProvider } from "./src/contexts/LanguageContext"

const fetch = require("node-fetch")

global.fetch = fetch

export const wrapRootElement = ({ element }) => (
  <LanguageProvider>{element}</LanguageProvider>
)
